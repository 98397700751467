/*
[USAGE]
  $form = $('#s3form')
  uploader = TS.S3Uploader.form_of($form)
  $form.find('input[type="file"]').on 'change', (e)=>
    uploader.policy_and_post(e.target.files[0]).then(
      (xhr, data)->
        console.log xhr.responseText
        $('#thumbnail').attr 'src', data.url
        $('#business_information_seal_image_key').attr 'value', data.id
    ,
      ()->
        console.log 'error'
        console.log xhr.responseText
    )
*/
class S3Uploader {
  // utility for construct from form
  static form_of(form) {
    var $form, method, policy_url;
    $form = $(form);
    policy_url = $form.attr('action');
    method = $form.attr('method');
    return new S3Uploader(policy_url, method);
  }

  constructor(policy_url1, method1) {
    this.policy_url = policy_url1;
    this.method = method1;
  }

  // get policy from @policy_url and post to S3
  policy_and_post(file) {
    var d, handle_error, params;
    handle_error = function(xhr) {
      return d.reject(JSON.parse(xhr.responseText), xhr);
    };
    params = {
      content_type: file.type,
      content_length: file.size,
      file_name: file.name
    };
    d = $.Deferred();
    $.ajax({
      url: this.policy_url,
      type: this.method,
      dataType: 'json',
      data: params
    }).then((data) => {
      var fd, name, xhr;
      fd = new FormData;
      for (name in data.form) {
        if (data.form.hasOwnProperty(name)) {
          fd.append(name, data.form[name]);
        }
      }
      fd.append('file', file);
      xhr = new XMLHttpRequest;
      xhr.open('POST', data.host, true);
      xhr.onreadystatechange = (aEvt) => {
        if (xhr.readyState === 4) {
          if (200 <= xhr.status && xhr.status < 300) {
            return d.resolve(xhr, data);
          } else {
            return handle_error(xhr);
          }
        }
      };
      return xhr.send(fd);
    }, (xhr, msg, t) => {
      return handle_error(xhr);
    });
    return d.promise();
  }
}

module.exports = S3Uploader;
