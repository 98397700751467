import $ from "expose-loader?exposes=$,jQuery!jquery" // [!]read jquery
import 'bootstrap';
import S3Uploader from '../src/commons/s3_uploader'
import Rails from "@rails/ujs" // [!] yarn add @rails/ujs

require.context('../images', true, /\.(png|jpg|jpeg|svg)$/); // [!] read images
Rails.start() // for link_to method: :remove

function init_seal_image() {
  var $form = $('#s3form');
  var uploader = S3Uploader.form_of($form);
  return $form.find('input[type="file"]').on('change', (e) => {
    return uploader.policy_and_post(e.target.files[0]).then(function(xhr, data) {
      $('#thumbnail').css('background-image', `url(${data.url})`);
      return $('#business_information_seal_image_key').attr('value', data.id);
    }, function(err) {
      return alert(err.message);
    });
  });
}

function init_tooltip(selector) {
  $(selector).tooltip();
}

function init_datepicker(selector) {
  $(selector).datepicker({
    dateFormat: 'yy-mm-dd'
  });
}

window.init_seal_image = init_seal_image;
window.init_tooltip = init_tooltip;
window.init_datepicker = init_datepicker;
